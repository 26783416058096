*{
	font-family: "Montserrat", sans-serif;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

h1 {
	margin-bottom: 20px;
	text-align: center;
}

h2 {
	text-align: center;
	margin: auto;
}

.container-center {
	display: flex;
	flex-direction: column;
	margin: 0;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.username-box {
	text-align: center;
	margin: auto;
}

.italic {
	font-style: italic;
}

.container-flex-center {
	background-color: #EDEBEE;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 100px 0 0 0;
	width: 100%;
	max-width: 100%;
	align-items: center;
  justify-content: center;
}




.App {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	margin-top:0px;
}

.scroll {
	overflow: scroll;
	margin-top: 0px;
	height: 100vh;
	background-color: #EDEBEE;
}

.page {
	flex-basis: 100%;
	margin-left: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
  min-height: 0;
	transition: margin-left 1s;
}

.page-with-navbar {
	margin-left: 15%;
}

.content {
	text-align: center;
	font-size: 1.2em;
}


.block {
	margin: 0px 50px 30px 50px;
	margin: 100px;
}

.block p {
	text-align: left;
}


.tw-btn {
	background-color: #1DA1F2;
	color: #fff;
	text-decoration: none;
	padding: 15px 25px;
	border-radius: 10px;
	font-size: 2em;
	font-weight: bold;
}

.tw-btn:hover {
	background-color: #0879bf;
}

.img-logo {
	max-width: 10%;
	margin-top: 15px;
}

.user {
	font-size: 3em;
	margin: auto;
}

.full-width {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 50%;
	padding: 25px;

}

.explorer-form input {
	font-size: 1.4em;
	border-radius: 10px;
	display: flex;
	margin-bottom: 10px;
	padding: 10px
}


table {
	margin-top: 20px;
	border-collapse: collapse;
	border: 1px solid;
	width: 100%;
}

th {
	background-color: #753ffd;
	color: #fff;
	border-right: 1px solid;
	border-bottom: 1px solid;
	text-align: center;
	padding: 5px;
}

td {
	border-right: 1px solid;
	border-bottom: 1px solid;
	padding: 5px;
	text-align: center;
}

.funder-address {
	border-bottom: 1px dotted black;
	text-decoration: underline;
}

.funder-address:before {
	content: attr(data-hover);
	visibility: hidden;
  opacity: 0;
  width: 550px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;

	position: absolute;
	z-index: 1;
	left: 0;
	top: 110%;
}

.funder-address:hover:before {
	opacity: 1;
	visibility: visible;
}

.rank-commitment {
	font-size: 1.2em;
}
.rank-commitment p {
	padding-top: 10px;
}

.rank-logo {
	width: 5%;
}

.container-rank-center {
	display: flex;
	flex-direction: column;
	margin: 100px auto 0 auto;
	width: 100%;
	align-items: center;
}

.rank-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 10px;
	padding: 10px;
}

.rank {
	min-width: 60%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	border-bottom: 2px solid;
	padding-bottom: 10px;
}

.rank-nb {
	color: #9B999C;
	flex-basis: 5%;
	margin-right: 10px;
}
.rank-name {
	font-weight: bold;
}

.rank-start-at {
	text-align: right;
	flex-grow: 2;
}

.rank-value {
	text-align: right;
}

.rank-valued {
	flex-basis: 100%;
	display: flex;
	justify-content: flex-end;
	text-align: left;
}

.rank-box{
	width: 150px;
	display: flex;
}

.rank-ergone {
	text-align: left;
	flex-grow: 2;
}


.title {
	color: #9B999C;
	font-weight: bold;
}

.rank-commitment table {
	width: 80%;
}

.ranking {
	font-size: 1.2em;
}
.ranking p {
	padding-top: 10px;
}

.ranking table {
	width: 60%;
}

.ranking table, tr, td, th {
	border: none;
	font-weight: bold;
}

.ranking td {
	border-bottom: 1px solid black;
	border-color: #9B999C;
	padding-top: 20px;
	padding-bottom: 20px;

}

.col-ranking-rank {
	width: 5%;
}
.col-ranking-user {
	width: 50%;
}
.col-ranking-score {
	width: 40%;
}


.col-rank {
	width: 5%;
}
.col-status {
	width: 50%;
}
.col-starts {
	width: 30%;
}
.col-voting-power {
	width: 15%;
}

.rank-commitment table, tr, td, th {
	border: none;
	font-weight: bold;
}

.rank-commitment td {
	border-bottom: 1px solid black;
	border-color: #9B999C;
	padding-top: 20px;
	padding-bottom: 20px;

}

.table-titles th {
	color: #9B999C;
	font-weight: bold;
	background-color: #EDEBEE;
}

.rank-numb {
	color: #9B999C;
	font-weight: bold;
	text-align: left;
}

.rank-status {
	font-weight: bold;
	text-align: left;
}

.rank-token {
	color: #9B999C;
	text-align: left;
	font-weight: normal;
}

.info-rankmycomit {
	font-style: italic;
	text-align: center;
	max-width: 80%
}

.commitment-example {
	text-align: left;
	font-size: 1.1em;
	margin-top: 15px;
}

.commitment-example .eg {
	text-decoration: underline;
	text-align: center;
}

.commitment-example .bold {
	font-weight: bold;
}

@media only screen and (max-width: 780px) {
	.page-with-navbar{
		width: 100%;
		margin-left: 0;
	}

	.full-width {
		width: 100%;
	}

	.table-dim {
		width: 100% !important;
		table-layout: fixed;
		word-break: break-word;
		font-size: 0.6em;
	}

	.tw-btn {
		font-size: 1.2em;
	}

}
