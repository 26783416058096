.flex-menu {
  display: flex;
  color: #828484;
  margin: 20px auto;
  cursor: pointer;
  font-size: 1.6em;
}

.flex-menu p {
  margin: auto 20px;
  font-style: bold;
  font-weight: 800;
}

.selected-menu {
  border-bottom: 4px solid #C301FF;
  color: #000;
}

.link-form {
  text-align: center;
}

.link-form input {
  width: 90%;
  font-size: 1em;
  margin: 20px 5% 20px 5%;
  padding-left: 5px;
}

.link-form button {
  margin: auto;
  font-size: 1em;

}

.link-btn {
  background-color: #06ceb0;
	color: #fff;
	text-decoration: none;
  margin-top: 10px;
	padding: 10px 10px;
	border-radius: 10px;
	font-size: 1.5em;
	font-weight: bold;
}

.link-btn:hover {
	background-color: #04A48C;
}

.url-submission-box {
  text-align: center;
  margin: 15px auto 15px auto;
}

.information {
  font-style: italic;
  text-align: center;
}

.link-info {
  font-style: italic;
  text-align: center;
  margin-top: 5px;
}

.submitted-message {
  font-style: italic;
  font-size: 1.5em;
  color: white;
  background-color: #C301FF;
}

.click {
  text-decoration: underline;
  font-size: 0.8em;
  margin-top: 5px;
  cursor: pointer;
}

.user-content {
  flex-basis: 80%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid;
  border-color: #ECEAEA;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #DCDEDC, -1px -1px 10px #DCDEDC;
  height: auto;
  width: 100%;
  max-width: 70%;
  margin: auto auto 10px auto;
  padding-top: 10px;
  justify-content: center;
  align-items: flex-start;
}

.user-content-submitted {
  background-color: #fff;
  border: 1px solid;
  border-color: #ECEAEA;
  color: #000;
}

.content-box {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  text-align: center;
  margin: auto;
}

.event-box {
  box-shadow: 1px 1px 10px #29C4A9, -1px -1px 10px #10463D;
}

.flex-embed {
  margin-left: 17%;
}

.spin-loader {
  padding-left: 45%;
}


.flex-item {
  text-align: center;
  word-break: break-word;
}

.img-flexbox {
  display: flex;
  justify-content: center;
}

.link-submitted-img {
  max-height: 300px;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.content-img {
  max-height: 150px;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.content-img-insta {
  width: 100%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
}

.user-link {
  text-align: center;
  font-size: 1em;
  color: grey;
  margin-left: 5px;
}

.user-link a {
  text-decoration: underline;
  color: grey;
}

.rss-title {
  color: black;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  padding: 0px 5px 0px 5px;
}

.flex-date-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.date {
  color: grey;
  font-size: 0.9em;
  margin-right: 5px;
}

.verified-content {
  color: #000;
  font-style: normal;
  background: #EDEDED;
  padding: 10px;
}


.verified-content p {
  padding: 0px;
  margin: 0px 10px;
  font-weight: bold;
}


.small {
  font-style: italic;
  font-size: 0.8em;
}

.flexbox-vote-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: auto 10px auto 10px;
}


.content-row {
	display: flex;
  flex-direction: column;
	flex-wrap: wrap;
  width: 100%;
	margin-top: 10px;
	align-items: center;
	justify-content: center;
}

.your-vote {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;

}


.your-vote-null {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  margin-right: 5px;
}

.vote {
  display: flex;
  flex-direction: column;
}


.vote-confirmation {
  background-color: #05D632;
  color: black;
  text-decoration: none;
  padding: 5px 5px;
  border-radius: 10px;
  margin: 5px auto;
  cursor: pointer;
  font-weight: normal;
  font-size: 0.8em;
}

.vote-confirmed {
  background-color: #E5E4E4;
  color: black;
  text-decoration: none;
  padding: 5px 5px;
  border-radius: 10px;
  margin: 5px auto;
  font-weight: normal;
  font-size: 0.8em;
}

.alert-msg {
  font-size: 0.8em;
  font-style: italic;
}

.options {
  display:flex;
  flex-wrap: wrap;
  justify-content:center;
  text-align: center;
}

.filter-user {
  margin-top: 5px;
  font-size: 1.2em;
}

.filter-field {
  margin: 10px 25px;
  width: 250px;
}

.select-points {
  margin-right: 5px;
  border-color: #E3E6E4;
  border-radius: 5px;
  font-size: 0.9em;
}

.no-vote-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  margin: 25px auto;
  width: 45%;
}

.no-vote-content h2 {
  align-self: center;
}


.no-vote-content h2 {
  font-weight: normal;
  cursor: pointer;
}

.separation {
  background-color: #C5C6C5;
  padding: 10px;
}

.attention  {
  color: #565555;
  font-size: 0.7em;
  border: solid 1px red;
  background-color: #E7E2E2;
}

.margin-top {
  margin-top: 25px;
}

.center {
  text-align: center;
  margin-bottom: 10px;
}

.tw-btn-small {
	color: #fff;
	text-decoration: none;
	padding: 15px 25px;
	border-radius: 10px;
	font-size: 1em;
	font-weight: bold;
}

.tw-btn-small:hover {
	background-color: #06ceb0;
}



.pocop p {
 margin: 15px 100px;
 font-size: 1.4em;
}

.pocop-logo {
 width: 15%;
 margin-bottom: 15px;
}

.btn-color {
  background-color: #979797;
}

.green {
  background-color: #06ceb0;
}

.content-img-twitter {
  width: 30%;
  margin: 10px auto;
}

.margin-tweet {
  margin: 10px auto;
}

.pointer {
  cursor: pointer;
}

.max-width {
  max-width: 100%;
}

.warning {
  font-size: 1.2em;
  margin-top: 10px;
  padding: 5px;
  border: 2px solid red;
}

.nbTx {
  border: 4px solid #06ceb0;
  padding: 10px;
  text-align: center;
  line-height: 1.5em;
  margin-bottom: 15px;
}

.nbTx span {
  font-size: 1.5em;
  font-weight: bold;
}

.italic {
  font-style: italic;
}

@media only screen and (max-width: 1700px) {

  .no-vote-content {
    width: 50%;
  }

}

@media only screen and (max-width: 1400px) {

  .no-vote-content {
    width: 70%;
  }

}

.admin-menu {
  display: flex;
  justify-content: center;
}

.admin-btn-delete {
  border: 1px solid black;
  background-color: #FA1104;
  width: fit-content;
  cursor: pointer;
  padding: 2px;
  margin: 10px;
  font-size:1em;
}

.admin-btn-delete:hover {
  font-size:1.2em;
}

.admin-btn-event {
  border: 1px solid black;
  background-color: #FAB704;
  width: fit-content;
  cursor: pointer;
  padding: 2px;
  margin: 10px;
  font-size:1em;
}

.admin-btn-event:hover {
  font-size:1.2em;
}

.admin-btn-validation {
  border: 1px solid black;
  background-color: #0495FA;
  width: fit-content;
  cursor: pointer;
  padding: 2px;
  margin: 10px;
}

.admin-btn-validation:hover {
  font-size:1.2em;
}

@media only screen and (max-width: 780px) {

  .user-content {
    flex-basis: 80%;
    max-width: 100%;
  }

  .filter-field {
    font-size: 0.8em;
    width: 150px;
  }

  .no-vote-content {
    width: 100%;
  }

  .flex-embed {
    margin-left: 0%;
  }

  .spin-loader {
    padding-left: 45%;
  }

  .flexbox-vote-share {
    font-size: 0.8em;
  }

  .pocop p {
   margin: 15px 25px;
   font-size: 1.4em;
  }

 .pocop-logo {
  width: 30%;
 }

}
