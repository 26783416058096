.feed-box {
	margin-top: 0px;
}

.full-height {
	min-height: 100%;
}


.container-flex-start {
	display: flex;
	flex-direction: column;
	margin: 25px 0 0 0;
	width: 100%;
	align-items: flex-start;
}

.feed-box-title {
	text-align: center;
	margin-top: 30px;
}

.rss-row {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	align-items: center;
	justify-content: center;
}

.rss-post {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 0px;
	padding: 0px;
}

.rss-column {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 10px;
	align-items: flex-start;
	justify-content: flex-start;
}

.loader {
	margin: auto;
	margin-top: 25%;
	text-align: right;
}

.loader p {
	font-style: italic;
	font-size: 0.8em;
}

.appeal-to-signin {
	width: 100%;
	background: linear-gradient(0.25turn, #C301FF, #29C4A9);
	color: white;
	margin-top: 0px;
	text-align: center;
	font-style: italic;
	font-size: 1.2em;
	padding-bottom: 20px;
	padding-top: 20px;
	margin-bottom: 10px;
}

.appeal-to-signin a {
	color: yellow;
}

.appeal-to-signin a:visited {
	color: white;
}

.main-filter-container {
	background-color: #EDEDED;
	text-align: center;
	margin-top: 10px;
}
.container-filters {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.filters-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: underline;
	padding: 10px 1rem;
	margin-bottom: 5px;
}

.filters-btn:hover {
	cursor: pointer;
}

.filters-to-save {
	margin-bottom: 10px;
	padding: 3px;
	font-size: 1em;
	margin-right: 40px;
	margin-top: 15px;
}

.filters-saved {
	margin-bottom: 10px;
	padding: 3px;
	font-size: 1em;
	background-color: #D5D2D2;
	margin-right: 40px;
	margin-top: 15px;
}

.checkbox-elmnt {
	flex-basis: 15%;
	text-align: left;
	display: flex;
	flex-wrap: wrap;
	margin: 10px auto 10px 20px;
}

.checkbox-box {
	margin-right: 10px;
}

.ergonauts-msg {
	width: 100%;
	background: linear-gradient(0.25turn, #C301FF, #29C4A9);
	color: white;
	margin-top: 0px;
	text-align: center;
	font-size: 1.4em;
	padding-bottom: 20px;
	padding-top: 20px;
	margin-bottom: 10px;
}

.ergonauts-msg a {
	color: white;
}

.tabs {
	display: flex;
	justify-content: center;
	width: 100%;
}


.tab {
	margin: 50px;
}


.tabs h2 {
	cursor: pointer;
}

.tab-selected {
	border-bottom: 3px solid #C301FF;
}


@media only screen and (max-width: 780px) {
  .checkbox-elmnt {
    flex-basis: 30%;
  }
}
