nav {
  position: absolute;
  min-height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15%;
  /*background-color: #753ffd;*/
  background: linear-gradient(#C301FF, #29C4A9);
  padding: 1rem 1rem 10px 1rem;
  transition: transform 1s;
}

.fixed {
  position: fixed;
  background-color: #753ffd;
}

.nav-btn {
  position: absolute;
  background-color: transparent;
  transform: translateX(38px);
  top: 20px;
  right: 0;
  width: 40px;
  height: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #753ffd;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
}

.logo {
  display: block;
  width: 100px;
  margin: auto;
  background: transparent;
}

.logo img {
  display: block;
  width: 100%;
  height: 100%;
}

.nav-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 10px 1rem;
  border-radius: 5px;
  margin-bottom: 5px;
}

.nav-link:hover {
  background-color: #AFB4FF;
  color: #4e4e4e;
}

.nav-link span {
  margin-left: 10px;
}

.nav-top {
  margin-top: 0rem;
}

  .mobile-nav {
  background: linear-gradient(0.25turn, #C301FF, #29C4A9);
  width: 100%;
  height: 40px;
  display: none;
}

.mobile-nav-btn {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 10px;
}

.display-user {
  color: #fff;
  text-align:  center;
  padding: 70px 0;
}

.display-user img {
  border-radius: 50%;
}

.log-nav-btn {
	background-color: #1DA1F2;
	color: #fff;
	padding: 10px 10px;
	margin-bottom: 10px;
	border-radius: 10px;
	font-size: 1.2em;
  border-style: solid;
  border-color: #1DA1F2;
  cursor: pointer;
}

.log-nav-btn:hover {
	background-color: #0879bf;
}


.nav-connect-btn {
  background-color: #1DA1F2;
  margin: 25px auto 25px auto;
  color: #fff;
  text-decoration: none;
  padding: 10px 10px;
  border-radius: 10px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
}

.nav-disconnect-btn {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 5px;
  border-radius: 10px;
  border-width: thin;
  background-color: #1DA1F2;
  color: #fff;
  padding: 1px 3px 1px 3px;
  cursor: pointer;
}

.token-logo {
  background-color: #26DCFC;
  color: #000;
  border-color: #17B924 ;
}

.token-logo img {
  width: 10%;
}

.high-menu {
  color: #fff;
  font-weight: bold;
}

.section {
  margin-bottom: 25px;
}

.nav-rank {
  color: #fff;
}

.addr-warning {
  font-style: italic;
  color: #fff;
  font-size: 0.8em;
}

.addr-warning

@media only screen and (max-width: 1050px) {
  nav {
    width: 40%;
    padding: 10px;
  }
}

@media only screen and (max-width: 780px) {
  .mobile-nav {
    display: flex;
    align-items: center;
  }

  .navbar {
    transform: translateX(-100%);
  }

  nav {
    width: 70%;
    top: 38px;
    min-height: calc(100vh - 38px);
  }

  .nav-btn {
    display: none;
  }

  .nav-connect-btn {
    display: none;
  }
}
