.container-flex-center {
	background-color: #EDEBEE;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 100px 0 0 0;
	width: 100%;
	max-width: 100%;
	align-items: center;
  justify-content: center;
}

.full-width {
	width: 100%;
}

.pocop-logo {
 width: 15%;
 margin-bottom: 15px;
}

.token-choice-flex {
	display: flex;
	flex-direction: column;
}

.token-choice-box {
	margin: 0 20px;
	border: 1px solid #fff;
}

.token-input {
	margin: auto 50px;
}

.event-presentation {
	text-align: left;
}

.txt-left {
	text-align: left;
}

.erg-choice {
	margin-bottom: 20px;
}

.flex-radio {
	display: flex;
	padding: 10px 0px 0px 10px;
	align-items: center;
}



.value-date-choice {
	font-size: 0.8em;
}

.group-event-title {
	font-size: 1.5em;
	font-weight: bold;
	margin: 5px auto;
}

.reward-type-choice {
	cursor: pointer;
	align-self: center;
}

.flex-radio label {
	margin-top: -4px;
}

.rounded-checkbox {
  width:20px;
  height: 20px;
  border-radius: 50%;
	background-color: white;
	margin: 2px 5px;
  border: 1px solid black;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: blue;
}

.chekbox:checked {
	background-color: red;
}


.submit-intro {
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center {
  text-align: center;
}

.underlined {
  text-decoration: underline;
}

.prices {
  padding-left: 50px;
}

.event-btn {
  background-color: #06ceb0;
	color: #fff;
	text-decoration: none;
  margin-top: 10px;
	padding: 10px 10px 6px 10px;
	border-radius: 10px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	max-width: 100%;
}

.scale {
	cursor: pointer;
}

.scale:hover {
	font-size: 1.1em;
}

.architect-box {
	border: 2px solid red;
	text-decoration: none;
  margin: 10px auto;
	padding: 10px 10px;
	border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.architect-box:hover {
	font-size: 1.1em;
}

.spin-loader {
	margin-top: 20px;
}

.event-h2 {
	margin: 20px auto 0px auto;
}

.oversized {
  font-size: 1.5em;
  font-weight: bold;
}

.event-form {
  display: flex;
  flex-direction: column;
	padding-top: 10px;
}

.event-form label {
  margin-bottom: 2px;
}

.mt-10-full-width {
	margin-top: 10px auto 0px auto;
	width: 100%;
	min-width: 100%;
	padding: 0px 10px;
}
.mt-10-full-width input, textarea {
	width: 100%;
}

.vert-align {
	vertical-align: middle;
}

.ml-10 {
	margin-left: 10px;
}

.event-form input, textarea {
  margin-bottom: 20px;
  font-size: 1.1em;
}

.form-intro {
  margin-bottom: 10px;
	text-align: center;
}

.form-intro p {
	font-style: italic;
}

.full-width {
	max-width: 100%;

}

.form-label {
	font-size: 1.3em;
	margin: -2% 5px auto 5px;
}

.form-label-legend {
	font-size: 0.8em;
}

.mt-10 {
	margin-top: 10px;
}

.calendar {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}

.warning-date {
	color: red;
	text-align: center;
}

.btn-submit {
	background-color: #1DA1F2;
	width: fit-content;
	margin: auto;
	color: #fff;
	text-decoration: none;
	padding: 15px 25px;
	border-radius: 10px;
	font-size: 1.2em;
	font-weight: bold;
}

.btn-submit:hover {
	background-color: #0879bf;
}

.close {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	cursor: pointer;
}


.event-resume {
	margin-top: 10px;
	border: 2px solid white;
}

.break-text {
  width: 100%;
  word-break: break-word;
}

.reduce-text {
	font-size: 0.8em;
	text-decoration: none;
}

.flex-column {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;

}

.mt-0 {
	margin-top: -50px;
}

.eventToVote-list {
	flex-basis: 45%;
	display: flex;
	flex-direction: column;
	border: 1px solid #000;
	padding: 10px;
	justify-content: center;
	text-align: center;
	border-radius: 10px;
}

.flex-vert-start{
	align-items: flex-start;
	align-content: flex-start;
}

.validated-event-box {
  box-shadow: 1px 1px 10px #29C4A9, -1px -1px 10px #10463D;
}

.padding-btn {
	padding: 5px 50px;
}

.eventToVote-box {
	display: flex;
	flex-wrap: wrap;
	border: 1px solid #000;
	margin: 5px auto;
	padding: 5px;
	width: 100%;
	justify-content: center;
	cursor: pointer;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.

.eventToVote:hover {
	background-color: red;
	font-size: 2em;
	text-decoration: underline;
}

.small-event-box {
	font-size: 1.2em;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.small-text {
	font-size: 1em;
	font-style: italic;
}

.font-smaller {
	font-size: 0.8em;
}

.margin-w-10 {
	margin: 10px 10px;
}

.event-votes-result {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}

.event-votes-result p {
	margin: auto;
}

.architect-box-vote {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}

.architect-box-vote p {
	margin: auto;
	border: 1px solid #000;
	border-radius: 20px;
	padding: 5px;
	cursor: pointer;
	min-width: 20%;
	text-align: center;
	font-weight: bold;
}

.bg-green {
	background-color: #45F104;
}

.bg-red {
	background-color: #FB6857;
}

.margin-5 {
	margin-top: 5px;
}

@media (max-width: 780px) {
	.eventToVote-list {
		flex-basis: 100%;
	}

	.rounded-checkbox {
		width: 17px;
		height: 17px;

	}
}
