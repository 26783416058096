.box {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 1.2em;
  margin: 10px auto 0 auto;
  padding: 2% 5%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.bold {
  font-weight: bold;
  margin-bottom: 5px;
}

.green {
  background-color: #06ceb0;
}

.x-share-btn {
  margin-top: 5px;
  border-radius: 10px;
}

.btn {
	background-color: #E5E4E4;
  font-size: 0.75em;
  color: black;
	text-decoration: none;
  padding: 5px 5px;
	border-radius: 10px;
  margin: auto 0% auto 0;

}

.row {
  display: flex;
  flex-direction: row;
}

.column-left {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: auto 75px auto 0px;
}

.column-right {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.1;
  flex-grow: 0.2;
  align-items: center;
  justify-content: center;
  margin: auto 0px auto 25px;
}


.break-text {
  width: 100%;
  word-break: break-word;
}


.rss-item {
  flex-basis: 31%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid;
  border-color: #ECEAEA;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #DCDEDC, -1px -1px 10px #DCDEDC;
  height: auto;
  margin: auto auto 10px auto;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
}

.ergo-item {
  flex-basis: 31%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid;
  border-color: #ECEAEA;
  border-radius: 10px;
  background-color: #F0F0F3;
  box-shadow: 1px 1px 10px #29C4A9, -1px -1px 10px #10463D;
  height: auto;
  margin: auto auto 10px auto;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
}

.hidden {
  visibility: collapse;
}

.flex-item {
  text-align: center;
}

.post-img {
  width: 250px;
  height: 125px;
  object-fit: contain;
  cursor: pointer;
}

.website-link {
  text-align: center;
}

.website-link a {
  font-size: 0.7em;
  text-decoration: none;
  color: grey;
}

.rss-title {
  color: black;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  padding: 0px 5px 0px 5px;
}

.flex-date-btn {
  display: flex;
  align-items: center;
}


.date {
  color: grey;
  font-size: 0.9em;
  padding-left: 5px;
}

.twitter-btn {
  width: max-content;
  margin: 10px 10px 10px 10px;
  background-color: #26a7de;
  border-color: #26a7de;
  border-radius: 2px;
  box-shadow: 2px 2px 2px grey;
  padding: 2px 8px 2px 5px;
  color: white;
}

.twitter-btn a {
  color: white;
  text-decoration: none;
  text-align: center;
}

.twitter-logo {
  vertical-align: -5px;
}

.connect-btn {
	background-color: #1DA1F2;
  margin: 25px auto 25px auto;
	color: #fff;
	text-decoration: none;
	padding: 10px 10px;
	border-radius: 10px;
	font-size: 1.3em;
	font-weight: bold;
  cursor: pointer;
}

.disconnect-link {
  margin-top: 15px;
  font-style: italic;
}

.address-info {
  font-style: italic;
  margin: 25px auto 10px auto;
}

.simple-address-info {
  font-size: 1.2em;
  margin: 25px auto 10px auto;
}

.simple-address-info span {
  font-weight: bold;
}


.img-payment {
  width: 25px;
  margin-right: 3px;
}

.flex-test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment {
  border: 1px solid;
  padding: 10px;
  margin: auto;
  text-align: center;
}

.payment-box {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.payment-box input {
  font-size: 1.3em;
  margin: 0px 15px 0px auto;
}

.token-select {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto auto 10px;
  font-size: 1.1em;
  background-color: white;
}

.message {
  margin-top: 10px;
  float: left;
  width: 100%;
  height: auto;
}

.message textarea {
  padding: 3px;
  float: left;
  width: 100%;
  outline: none;
  resize: none;
  border: 1px solid grey;
}

.tip-btn {
  margin: 10px auto auto auto;
  background-color: #1DA1F2;
  color: #fff;
  text-decoration: none;
  padding: 10px 10px;
  border-radius: 10px;
  font-size: 1em;
  font-weight: bold;
}

.tx-signed {
  font-size: 1.2em;
  margin-bottom: 15px;
  font-style: bold;
  border: 1px solid;
  padding: 10px;
  background-color: #FAF6F6;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.tx-signed p {
  margin: 10px auto 10px auto;
  align-self: center;
}

.no-fund {
  align-self: flex-start;
  font-style: italic;
  color: red;
}

.box-purple {
  width: 100%;
  background-color: #753ffd;
  color: white;
}

.box-purple h3 {
  margin: 10px auto 10px auto;
}

.box-purple a:visited {
  color: yellow;
}

.box-purple a {
  color:
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mobile {
  display: none;
}

.max-size {
  width: 100%;
  max-width: 100%;
  text-align: center;
}


@media (max-width: 780px) {
  .row {
    font-size: 12px;
  }

  .onboarding {
    font-size: 0.7em;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
  }
}
