.address-form {
  text-align: center;
}

.address-form input {
  width: 90%;
  font-size: 1.2em;
  margin: 20px 5% 20px 5%;
  padding-left: 5px;
}

.address-form button {
  font-size: 1.2em;
  margin: auto;
}

.center {
  text-align: center;
  font-size: 1.2em;
}

.bold {
  font-weight: bold;
}

.success-message {
  display: flex;
  margin: auto;
}

.address {
  word-break: break-word;
}

.flex-item {
  flex-basis: auto;
  margin: auto;
}

.flex-item p {
  margin: 10px auto 50px auto;
}

.flex-item .tw-btn {
  font-size: 0.8em;
}

.current-wallet {
  text-align: center;
  margin-top: 15px;
  font-style: italic;
  word-break: break-word;
}

.address-submit-choice {
  margin-top: 20px;
  text-align: center;
}

.reload {
  cursor: pointer;
  text-decoration: underline;
}


@media (max-width: 780px) {
  .flex-item {
    font-size: 0.8em;
  }

  .address {
    font-size: 0.8em;
  }
}
